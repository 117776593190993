<template>
    <div>
        <v-container>
            <v-row dense class="mt-12 pt-12 mb-12">
                <v-col cols="12" xl="8" lg="10" md="12" offset-xl="2" offset-lg="1">
                    <h2 class="text-center">Verificación de cuenta</h2>
                    <p class="error--text text-center">Código de verificación expira en 5 minutos.</p>
                    <v-card>
                        <v-card-text>
                            <div class="form-group">
                                <label>
                                    Ingresa el código
                                    <el-input 
                                        autofocus
                                        placeholder="2356"
                                        v-model="form.code"
                                    >
                                    </el-input>
                                </label>
                            </div>
                            <v-btn color="secondary" outlined @click.stop.prevent="verify()">
                                Verificar código
                            </v-btn>
                        </v-card-text>
                        <v-card-text>
                            <p>
                                ¿Aún no te llega el código? seleciona el medio por el cuál quiere verificar sus datos.
                            </p>
                            <div class="form-group">
                                <!-- <el-radio-group v-model="form.via" class="pt-4">
                                    <el-radio label="sms">Mensaje de texto</el-radio>
                                    <el-radio label="email">Correo electrónico</el-radio>
                                </el-radio-group> -->
                            </div>
                            <v-btn outlined color="primary" @click="verifyResend()">
                                reenvíar código
                            </v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    
    export default {
        data() {
            return {
                form: {
                    code: null,
                    via: 'email'
                }
            }
        },
        computed: {
            ...mapGetters(['location'])
        },
        methods: {
            ...mapActions({
                addNotification: 'addNotification'
            }),
            verify() {
                this.$store.dispatch('verify', this.form).then((response) => {
                    if (response.data.success) {
                        this.addNotification({
                            show: true,
                            type: 'success',
                            message: response.data.message
                        }).then(() => {
                            // if (this.location) {
                                this.$router.push({name: 'payment'})
                            // } else {
                            //     this.$router.push({name: 'cart'})
                            // }
                        })

                    } else {
                        this.addNotification({
                            show: true,
                            type: 'error',
                            message: response.data.message
                        })
                    }
                }).catch(error => {
                    this.addNotification({
                        show: true,
                        type: 'error',
                        message: error.response.data.message
                    })
				}).then(() => {
					this.loadingSubmit = false
				});
            },
            verifyResend() {
                this.$store.dispatch('verifyResend', this.form).then((response) => {
                    if (response.data.success) {
                        this.addNotification({
                            show: true,
                            type: 'success',
                            message: response.data.message
                        })
                    } else {
                        this.addNotification({
                            show: true,
                            type: 'error',
                            message: response.data.message
                        })
                    }
                });
            }
        }
    }
</script>
